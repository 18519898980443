<template>
  <div class="analysis">
    <van-nav-bar
      title="统计分析"
      :border="false"
      left-arrow
      @click-left="$router.back()"
    >
    </van-nav-bar>
    <van-list class="analysis__list">
      <van-cell
        v-for="(item, idx) in analysisConf"
        :key="idx"
        is-link
        clickable
        :title="item.title"
        class="analysis__list-item"
        :to="{ name: item.routerName }"
        :border="idx !== analysisConf.length - 1"
      >
        <template #title>
          <van-icon
            :name="item.icon"
            :color="item.iconColor"
            class-prefix="iconfont"
            class="van-cell__left-icon"
          />
          <span>{{ item.title }}</span>
        </template>
      </van-cell>
    </van-list>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Analysis",
  data() {
    return {
      analysisConf: [],
      analysisConfBackup: [
        {
          icon: "tongjifenxi-baojingtongji",
          iconColor: "#FC4935",
          title: "报警统计",
          routerName: "AlarmAnalysis",
          auth: "alarm"
        },
        {
          icon: "tongjifenxi-zuoyetongji",
          iconColor: "#1D9EFF",
          title: "作业统计",
          routerName: "WorkTicketAnalysis",
          auth: "workticket"
        },
        {
          icon: "tongjifenxi-yinhuantongji",
          iconColor: "#FFB41F",
          title: "隐患统计",
          routerName: "TroubleAnalysis",
          auth: "corrections"
        },
        {
          icon: "gaishantongji",
          iconColor: "#1EB6CD",
          title: "改善统计",
          routerName: "ImproveAnalysis",
          auth: "improve-corrections"
        }
      ]
    };
  },
  computed: {
    ...mapState({
      authRoutes: state => state.menu.roles
    })
  },
  mounted() {
    this.formatAnalysisConf();
  },
  methods: {
    formatAnalysisConf() {
      this.analysisConf = this.analysisConfBackup.filter(v =>
        this.authRoutes.some(s => s === v.auth)
      );
    }
  }
};
</script>

<style lang="scss">
.analysis {
  &__list {
    .van-cell::after {
      border-bottom: 1px solid #ebebeb;
    }
  }

  &__list-item {
    padding: 15px 20px;

    .van-cell__left-icon {
      font-size: 24px;
      margin-right: 8px;
      vertical-align: -4px;
    }

    span {
      color: #333333;
      font-size: 15px;
    }
  }
}
</style>
